import ajax from '@/utils/ajax.js'

/**
 * 获取收银软件列表
 * @param {*} params 
 * @returns 
 */
export function getList(params) {
  return ajax.post('/OfflineCashRegister/getList',params)
}

/**
 * 添加收银软件列表
 * @param {*} params 
 * @returns 
 */
export function addCashier(params) {
  return ajax.post('/OfflineCashRegister/add',params)
}

/**
 * 修改收银软件列表
 * @param {*} params 
 * @returns 
 */
export function updateCashier(params) {
  return ajax.post('/OfflineCashRegister/update',params)
}


/**
 * 查询推送记录
 * @param {*} params 
 * @returns 
 */
export function getPushList(params) {
  return ajax.post('/OfflineCashRegister/getPushList',params)
}

/**
 * 重推
 * @param {*} params 
 * @returns 
 */
export function pushRetry(params) {
  return ajax.post('/OfflineCashRegister/pushRetry',params)
}

