<template>
  <div>
    <!-- 新增 编辑modal -->
    <a-modal :visible="show"
             width="600px"
             :title="type=='add'?'新增收银软件': '编辑收银软件'"
             @ok="handlerSave" @cancel="$emit('update:show', false)">
      <a-form-model ref="addForm"
                    :labelCol="{ span: 3 }"
                    :wrapperCol="{ span: 21 }"
                    :model="formData">
        <a-form-model-item prop="name" label="名称">
          <a-input allowClear
                   v-model="formData.name"
                   placeholder="收银软件名称"></a-input>
        </a-form-model-item>
        <a-form-model-item prop="app_id" label="app_id">
          <a-input allowClear
                   v-model="formData.app_id"
                   placeholder="app_id"></a-input>
        </a-form-model-item>
        <a-form-model-item prop="app_key" label="app_key">
          <a-input v-if="type=='add'" allowClear
                   v-model="formData.app_key"
                   placeholder="app_key"></a-input>
          <div v-else class="flex">
            <template v-if="isEditAppKey">
              <a-input allowClear
                     v-model="edit_app_key"
                     placeholder="填写新app_key"></a-input>
              <a-button class="ml-2" 
                    @click="handlerUpdate('app_key')">保存</a-button>
            </template>
            <template v-else>
              <a-input disabled
                     v-model="formData.app_key"
                     placeholder="app_key"></a-input>
              <a-button class="ml-2" type="link"
                    @click="isEditAppKey=true">修改</a-button>
            </template>
          </div>
        </a-form-model-item>
        <a-form-model-item prop="push_order_url" label="推送地址">
          <a-input v-if="type=='add'" allowClear
                   v-model="formData.push_order_url"
                   placeholder="推送地址"></a-input>
          <div v-else class="flex">
            <template v-if="isEditPushUrl">
              <a-input allowClear
                     v-model="edit_push_url"
                     placeholder="填写新推送地址"></a-input>
              <a-button class="ml-2" 
                    @click="handlerUpdate('push_order_url')">保存</a-button>
            </template>
            <template v-else>
              <a-input disabled
                     v-model="formData.push_order_url"
                     placeholder="推送地址"></a-input>
              <a-button class="ml-2" type="link"
                    @click="isEditPushUrl=true">修改</a-button>
            </template>
          </div>
        </a-form-model-item>
        
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import {
  addCashier,
  updateCashier
} from "@/api/system/cashier.js"

export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'add'
    },
    info: {
      type: Object,
      default: null
    },
  },
  data () {
    return {
      formData: {
        name: "",
        app_id: "",
        app_key: "",
        push_order_url: "",
      },

      isEditAppKey: false,
      isEditPushUrl: false,
      edit_app_key: "",
      edit_push_url: "",
    }
  },
  async mounted(){
    if(this.type === 'edit'){
      this.formData = Object.assign(this.formData,this.info)
    }
  },
  methods: {
    // async initData(){
    //   const res = await getPaymentDetail({id: this.id})
    //   this.formData = res.data
    //   this.formData.id = this.id
    // },

    handlerSave () {
      if (this.type === "add") {
        addCashier(this.formData).then((res) => {
          if (res.code === 0) {
            this.$message.success("新增成功！")
            this.$emit('update:show', false)
            this.$parent.initData()
          }
        })
      } else {
        updateCashier({
          id: this.formData.id,
          name: this.formData.name,
          app_id: this.formData.app_id,
        }).then((res) => {
          if (res.code === 0) {
            this.$message.success("修改成功！")
            this.$emit('update:show', false)
            this.$parent.initData()
          }
        })
      }
    },

    handlerUpdate(key){
      if(key=="app_key"){
        updateCashier({
          id: this.formData.id,
          app_key: this.edit_app_key,
        }).then((res) => {
          if (res.code === 0) {
            this.$message.success("修改成功！")
            this.$parent.initData()
          }
        })
      }
      if(key=="push_order_url"){
        updateCashier({
          id: this.formData.id,
          push_order_url: this.edit_push_url,
        }).then((res) => {
          if (res.code === 0) {
            this.$message.success("修改成功！")
            this.$emit('update:show', false)
            this.$parent.initData()
          }
        })
      }
    },


  }
}
</script>

<style>
.recharge-account{
  align-items: center;
  
}
.recharge-account .ant-select{
  flex: 1;
  margin-right: 20px;
}

</style>