<template>
  <div>
    <base-table ref="tableRef"
                :columnsData="columns"
                rowKey="id"
                :tableData="tableData">
      <template #toolbar>
        <div class="flex justify-between">
          <h4 class="text-lg font-bold">收银系统</h4>
          <a-button class="ml-3" type="primary" @click="handlerEdit('add')" html-type="submit">新增</a-button>
        </div>
      </template>
      <template #operation="{record}">
        <a-button class="p-0" type="link" @click="handlerEdit('edit',record)">编辑</a-button>
      </template>
    </base-table>

    <add-modal v-if="isEidtShow" :show.sync="isEidtShow" :info="activeRow" :type="type" />

  </div>
</template>

<script>
import {
  getList,
} from "@/api/system/cashier.js"
import addModal from './components/add-modal.vue'

export default {
  components: { addModal },
  data () {
    return {
      columns: [
        {
          title: "ID",
          dataIndex: "id",
          align: "center",
          width: 70
        },
        {
          title: "收银软件",
          dataIndex: "name",
          align: "center",
          width: 120
        },
        {
          title: "appid",
          dataIndex: "app_id",
          align: "center",
          width: 120
        },
        {
          title: "appkey",
          dataIndex: "app_key",
          align: "center",
          width: "30%"
        },
        {
          title: "推送地址",
          dataIndex: "push_order_url",
          align: "center",
          width: "70%"
        },
        {
          title: "操作",
          dataIndex: "operation",
          slots: { customRender: "operation" },
          align: "center",
          width: 90,
        },
      ],

      tableData: [],

      // activeId: '',
      activeRow: null,
      type: '',
      isEidtShow: false,
    }
  },
  mounted () {
    this.initData()
  },
  methods: {
    async initData () {
      const { data, code } = await getList()
      if (code === 0) {
        this.tableData = data.list
      }
    },

    handlerEdit(type, row) {
      this.type = type
      // this.activeId = row ? row.id : ''
      this.activeRow = row
      this.isEidtShow = true
    },
  },
}
</script>

<style>
</style>